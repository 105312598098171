import React from "react";
import { Box } from "@mui/material";
import { BSTypography } from "./BSTypography";

import "../css";

export interface InstrumentLabelStackProps {
  label: string;
  value: string;
  unit: string;
  // status: string;
  size: "s" | "m" | "l";
  row: boolean;
  alignCenter?: boolean;
  autoScale?: boolean;
  enhanced: boolean;
  baseLabel?: boolean;
  valueType: "input" | "dynamic" | "static";
}

export const BSInstrumentLabel = (props: InstrumentLabelStackProps) => {
  let justifyContentBox = "";
  let alignItemsBox = "";
  let alignItemsValueBox = "";

  if (props.row) {
    alignItemsBox = "baseline";
    alignItemsValueBox = "baseline";
    if (!props.autoScale) {
      justifyContentBox = "space-between";
    }
  } else if (props.alignCenter) {
    alignItemsBox = "center";
    alignItemsValueBox = "center";
  } else {
    alignItemsBox = "flex-start";
    alignItemsValueBox = "baseline";
  }

  return (
    <Box
      display="flex"
      flexDirection={props.row ? "row" : "column"}
      justifyContent={justifyContentBox}
      alignItems={alignItemsBox}
    >
      <BSTypography
        variant="instrument-label"
        size={props.baseLabel ? "s" : props.size}
        enhanced={props.enhanced}
      >
        {props.label}
      </BSTypography>
      <Box
        display="flex"
        flexDirection={!props.alignCenter || props.row ? "row" : "column"}
        alignItems={alignItemsValueBox}
      >
        <BSTypography
          variant={`instrument-${props.valueType}`}
          size={props.size}
          enhanced={props.enhanced}
        >
          {props.value}
        </BSTypography>
        <BSTypography variant="instrument-unit" size={props.size}>
          {props.unit}
        </BSTypography>
      </Box>
    </Box>
  );
};
