import React from "react";
import { SvgIconProps, SvgIcon } from "@mui/material";

export type BSIconProps = SvgIconProps & {
  icon?: typeof SvgIcon;
  flavor:
    | "flat"
    | "normal"
    | "running"
    | "alarm"
    | "critical-alarm"
    | "caution"
    | "warning";
  emphasized?: boolean;
  disabled?: boolean;
};

export const BSIcon = (props: BSIconProps) => {
  const {
    icon,
    flavor: flavor0,
    emphasized: emphasized0,
    // disabled,
    // children,
    ...other
  } = props;

  const flavor = flavor0 || "normal";
  const emphasized = emphasized0 || false;

  let style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "2rem",
    minWidth: "2rem",
    color: `var(--color-${flavor}-enabled-background-color)`,
    "&:hover": {
      color: `var(--color-${flavor}-hover-background-color)`,
    },
    "&:active": {
      color: `var(--color-${flavor}-pressed-background-color)`,
    },
    "&:focus-visible": {
      color: `var(--color-${flavor}-focused-background-color)`,
    },
    "&:disabled": {
      color: `var(--color-${flavor}-disabled-background-color)`,
    },
  };

  if (flavor === "normal" || flavor === "flat" || emphasized) {
    const neutralColor = `var(--color-on-${flavor}-neutral-color)`;
    const activeColor = `var(--color-on-${flavor}-active-color)`;
    const disabledColor = `var(--color-on-${flavor}-disabled-color)`;
    style = {
      ...style,
      color: neutralColor,

      "&:hover": {
        color: neutralColor,
      },
      "&:active": {
        color: activeColor,
      },
      "&:focus-visible": {
        color: neutralColor,
      },
      "&:disabled": {
        color: disabledColor,
      },
    };
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...other} sx={{ ...style }}>
      {icon}
    </SvgIcon>
  );
};
