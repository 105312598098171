import React, { useState, useEffect } from "react";

import {
  // SimpleDiv,
  MaritimeChart,
} from "@blksail/arcgis-charts";

import { Box, SvgIcon } from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";

import { CurrentAdvisedInstrument } from "./CurrentAdvisedInstrument";
import { ConnectionStatusIcon } from "./ConnectionStatusIcon";

import mqtt from "mqtt";
import {
  VesselsFeatures,
  VesselFeature,
  WaypointsFeatures,
} from "@blksail/arcgis-charts/dist/types/interfaces";
import { BSCard, BSAppBar } from "@blksail/blksail-components";
import { ChangeColorButton } from "./ChangeColorButton";

const ONLINE = true;
let PREFIX = "MOOS/";
let MQTT_URL = "ws://192.168.1.101:1884";
if (ONLINE) {
  MQTT_URL = "wss://test.mosquitto.org:8081";
  PREFIX = "BLKSAIL/ONLINE/LIVE/";
}
const OWNSHIP_TOPIC: string = `${PREFIX}+/+/BS_GEOJSON_OWNSHIP`;
const WAYPOINTS_TOPIC: string = `${PREFIX}+/+/BS_GEOJSON_WAYPOINTS`;
const GRAPHICS_TOPIC: string = `${PREFIX}+/+/BS_GEOJSON_GRAPHICS`;
const CONTACTS_TOPIC: string = `${PREFIX}+/+/BS_GEOJSON_CONTACTS`;

const mqttTopicToRegExp = (topic: string): RegExp => {
  const topicParts = topic.split("/");
  const regexpParts = topicParts.map((part) => {
    if (part === "+") {
      return ".*";
    }
    return part;
  });
  return new RegExp(`^${regexpParts.join("/")}$`);
};

export const App = () => {
  const [zoom] = useState(13);
  const [center, setCenter] = useState<[number, number]>([
    -70.876455, 42.388603,
  ]);
  const [, setLat] = useState(42.388603);
  const [, setLon] = useState(-70.876455);

  const [colorScheme, setColorScheme] = React.useState<
    "bright" | "day" | "dusk" | "night"
  >("day");

  const handleColorClick = () => {
    setColorScheme((c) => {
      const col = ["bright", "day", "dusk", "night"][
        (["bright", "day", "dusk", "night"].indexOf(c) + 1) % 4
      ] as "bright" | "day" | "dusk" | "night";

      // set the color in the html component
      document.documentElement.setAttribute("theme", col);

      return col;
    });
  };

  const [mqttConnected, setMqttConnected] = useState(false);

  const [ownshipTrack, setOwnshipTrack] = useState<VesselsFeatures>({
    type: "FeatureCollection",
    features: [],
  });
  const [waypoints, setWaypoints] = useState<WaypointsFeatures>({
    type: "FeatureCollection",
    features: [],
  });
  const [aisData, setAisData] = useState<VesselsFeatures>({
    type: "FeatureCollection",
    features: [],
  });

  const [linestrings, setLinestrings] =
    useState<GeoJSON.FeatureCollection<GeoJSON.LineString>>();

  const [points, setPoints] =
    useState<GeoJSON.FeatureCollection<GeoJSON.Point>>();

  // mqtt setup
  useEffect(() => {
    const mqttClient = mqtt.connect(MQTT_URL);
    if (mqttClient) {
      const ownshipRegEx = mqttTopicToRegExp(OWNSHIP_TOPIC);
      const waypointsRegEx = mqttTopicToRegExp(WAYPOINTS_TOPIC);
      const graphicsRegEx = mqttTopicToRegExp(GRAPHICS_TOPIC);
      const contactsRegEx = mqttTopicToRegExp(CONTACTS_TOPIC);

      const subscribeTo = (topic: string) => {
        mqttClient.subscribe(topic, (err) => {
          if (err) {
            console.error(err);
          } else {
            console.log(`subscribed to ${topic}`);
          }
        });
      };

      mqttClient.on("connect", () => {
        subscribeTo(OWNSHIP_TOPIC);
        subscribeTo(WAYPOINTS_TOPIC);
        subscribeTo(GRAPHICS_TOPIC);
        subscribeTo(CONTACTS_TOPIC);
        setMqttConnected(true);
      });

      const onOwnship = (message: string) => {
        const data = JSON.parse(message);
        const { stringvalue } = data;
        const feature: VesselFeature = JSON.parse(stringvalue);
        setOwnshipTrack((ownshipTrack) => {
          const newOwnshipTrack = {
            ...ownshipTrack,
            features: [feature],
          };
          // newOwnshipTrack.features.push(feature);
          // newOwnshipTrack.features = [...newOwnshipTrack.features.slice(-1)];

          return newOwnshipTrack;
        });
        setLat(feature.geometry.coordinates[1]);
        setLon(feature.geometry.coordinates[0]);

        setCenter([
          feature.geometry.coordinates[0],
          feature.geometry.coordinates[1],
        ]);
      };

      const onWaypoints = (message: string) => {
        const data = JSON.parse(message);
        const { stringvalue } = data;
        const waypoints: WaypointsFeatures = JSON.parse(stringvalue);
        setWaypoints(waypoints);
      };

      const onContacts = (message: string) => {
        const data = JSON.parse(message);
        const { stringvalue } = data;
        const contacts: VesselsFeatures = JSON.parse(stringvalue);
        setAisData(contacts);
      };

      const onGraphics = (message: string) => {
        const data = JSON.parse(message);
        const { stringvalue } = data;
        const features: GeoJSON.Feature[] = JSON.parse(stringvalue);
        // lines are extracted from the graphics using geometry.type === "LineString"
        const lines: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
          type: "FeatureCollection",
          features: features.filter(
            (feature) => feature.geometry.type === "LineString"
          ) as GeoJSON.Feature<GeoJSON.LineString>[],
        };
        lines.features = lines.features.map((f) => {
          const newFeature = {
            ...f,
            properties: {
              ...f.properties,
              color: f.properties?.edge_color || "red",
            },
          };
          return newFeature;
        });
        setLinestrings(lines);

        // points are extracted from the graphics using geometry.type === "Point"
        const points: GeoJSON.FeatureCollection<GeoJSON.Point> = {
          type: "FeatureCollection",
          features: features.filter(
            (feature) => feature.geometry.type === "Point"
          ) as GeoJSON.Feature<GeoJSON.Point>[],
        };
        setPoints(points);
      };

      mqttClient.on("message", (topic, message) => {
        // console.log(topic, message.toString());
        if (ownshipRegEx.test(topic)) {
          onOwnship(message.toString());
        } else if (waypointsRegEx.test(topic)) {
          onWaypoints(message.toString());
        } else if (graphicsRegEx.test(topic)) {
          onGraphics(message.toString());
        } else if (contactsRegEx.test(topic)) {
          onContacts(message.toString());
        }
      });

      mqttClient.on("close", () => {
        console.log("MQTT connection closed");
        setMqttConnected(false);
      });
    }

    return () => {
      if (mqttClient) {
        mqttClient.end();
        console.log("MQTT ended");
      }
    };
  }, []);

  return (
    <div
      // Top Layer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",

        // self
        padding: 0,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <BSAppBar title="blkSAIL" subTitle="Demo">
        <ChangeColorButton onClick={handleColorClick} />
        <ConnectionStatusIcon connected={mqttConnected} />
      </BSAppBar>
      <Box
        // Data Layer
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",

          // self
          order: 1,
          alignSelf: "stretch",
          margin: 0,
        }}
      >
        <BSCard
          title="Decision Aid"
          headerIcon={(<MemoryIcon />) as unknown as typeof SvgIcon}
          sx={{
            zIndex: 1,
            margin: 1,
            // width: "100%",
            boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            // Content Frame
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 0,
              width: "100%",
              gap: "3rem",
            }}
          >
            <CurrentAdvisedInstrument
              instrument="HDG"
              currentValue="0˚"
              advisedValue="0˚"
              unit=""
            />
            <CurrentAdvisedInstrument
              instrument="SPD"
              currentValue="0"
              advisedValue="0"
              unit="kn"
            />
          </div>
        </BSCard>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "3rem",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      >
        <MaritimeChart
          zoom={zoom}
          colorSchemeENC={colorScheme}
          center={center}
          showOwnship
          ownshipTrack={ownshipTrack}
          waypoints={waypoints}
          aisData={aisData}
          lineStrings={linestrings}
          points={points}
        />
      </Box>
    </div>
  );
};
