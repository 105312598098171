import * as React from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import { BSIcon } from "./BSIcon";
import { SvgIcon } from "@mui/material";
import EjectTwoToneIcon from "@mui/icons-material/EjectTwoTone";

import { BSTypography } from "../Labels/BSTypography";

import "../css";

export interface BSAppBarProps {
  title: string;
  subTitle: string;
  menuIcon?: typeof SvgIcon;
  onMenuClick?: () => void;
  onBackClick?: () => void;
  onNextClick?: () => void;
  onSettingsClick?: () => void;
  onHelpClick?: () => void;
  onLogoutClick?: () => void;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

const appBarStyle = {
  color: "var(--color-on-flat-neutral-color)",
  backgroundColor: "var(--color-container-global-color)",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  height: "3rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0",
  position: "static",
  zIndex: 100,
} as React.CSSProperties;

export const BSAppBar = (props: BSAppBarProps) => {
  const {
    title,
    subTitle,
    menuIcon,
    // onMenuClick,
    // onBackClick,
    // onNextClick,
    // onSettingsClick,
    // onHelpClick,
    // onLogoutClick,
    sx,
  } = props;

  return (
    <header
      // style={appBarStyle}
      style={{
        ...(sx as React.CSSProperties),
        ...appBarStyle,
      }}
    >
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            padding: "0.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: "0",
          }}
        >
          <BSIcon
            flavor="flat"
            icon={
              menuIcon || ((<EjectTwoToneIcon />) as unknown as typeof SvgIcon)
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // padding: 0,
            padding: "0 0 0 0.5rem",

            flexGrow: 1,
          }}
        >
          <BSTypography style={{ paddingRight: "0.5rem" }} variant="body">
            {title}
          </BSTypography>
          <BSTypography variant="body-active">{subTitle}</BSTypography>
        </Box>
        {/* <Button color="inherit">Login</Button> */}
        <Box
          sx={{
            padding: "0.5rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
            flex: "0",
          }}
        >
          {props.children}
        </Box>
      </div>
    </header>
  );
};
