import React from "react";
import { SvgIcon } from "@mui/material";
import { BSIcon } from "@blksail/blksail-components";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

export interface ConnectionStatusIconProps {
  connected: boolean;
}

export const ConnectionStatusIcon = (props: ConnectionStatusIconProps) => {
  const { connected } = props;
  let icon = <LinkOffIcon />;
  let flavor = "warning" as "warning" | "running";
  if (connected) {
    icon = <LinkIcon />;
    flavor = "running";
  }

  return (
    <BSIcon
      icon={icon as unknown as typeof SvgIcon}
      flavor={flavor}
      emphasized={false}
      style={{
        paddingRight: "0.25rem",
        paddingLeft: "0.25rem",
      }}
    />
  );
};
