import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { SimpleLineSymbol } from "@arcgis/core/symbols";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import Symbol from "@arcgis/core/symbols/Symbol";
import { geojsonToArcGIS } from "@terraformer/arcgis";
import circle from "@turf/circle";
import {
  polygon,
  lengthToDegrees,
  Position,
  multiLineString,
  lineString,
} from "@turf/helpers";
import transformRotate from "@turf/transform-rotate";
import {
  VesselFeature,
  VesselsFeatures,
  WaypointFeature,
  WaypointsFeatures,
  GeometryFeature,
  GeometriesFeatures,
} from "./interfaces";

export const getVesselShape = (feature: VesselFeature) => {
  if (
    !(
      feature &&
      feature.geometry &&
      feature.geometry.coordinates &&
      feature.properties
    )
  ) {
    return undefined;
  }
  const bowLength =
    // feature.properties.A && feature.properties.B
    //   ? (feature.properties.A + feature.properties.B) * 0.1
    //   : 0;
    5;
  const A = feature.properties?.A || 10;
  const B = feature.properties?.B || 10;
  const C = feature.properties?.C || 5;
  const D = feature.properties?.D || 5;
  const bowLengthInDegrees = lengthToDegrees(bowLength, "meters");
  const toBowInDegrees = lengthToDegrees(A - bowLength, "meters");
  const toSternInDegrees = lengthToDegrees(B, "meters");
  const toPortInDegrees = lengthToDegrees(C, "meters");
  const toStarboardInDegrees = lengthToDegrees(D, "meters");

  const bowPort: Position = [
    feature.geometry.coordinates[0] + toPortInDegrees,
    feature.geometry.coordinates[1] + toBowInDegrees,
  ];
  const bowMidShip: Position = [
    feature.geometry.coordinates[0] +
      (toPortInDegrees - toStarboardInDegrees) / 2,
    feature.geometry.coordinates[1] + toBowInDegrees + bowLengthInDegrees,
  ];
  const bowStarboard: Position = [
    feature.geometry.coordinates[0] - toStarboardInDegrees,
    feature.geometry.coordinates[1] + toBowInDegrees,
  ];
  const sternPort: Position = [
    feature.geometry.coordinates[0] + toPortInDegrees,
    feature.geometry.coordinates[1] - toSternInDegrees,
  ];
  const sternStarboard: Position = [
    feature.geometry.coordinates[0] - toStarboardInDegrees,
    feature.geometry.coordinates[1] - toSternInDegrees,
  ];

  let shape = polygon(
    [[bowPort, bowMidShip, bowStarboard, sternStarboard, sternPort, bowPort]],
    {
      name: feature.properties.name,
    }
  );
  shape = transformRotate(shape, feature.properties.heading, {
    pivot: feature.geometry.coordinates,
  });
  return { ...shape, properties: feature.properties };
};

export const getVesselCourseOverGroundArrow = (feature: VesselFeature) => {
  const length = feature.properties.speed_over_ground
    ? feature.properties.speed_over_ground * 2
    : 50;
  const height = length / 6;
  const arrowLength = lengthToDegrees(length, "meters");
  const widthLength = lengthToDegrees(5, "meters");
  const heightLength = lengthToDegrees(height, "meters");

  const base = feature.geometry.coordinates;
  const head: Position = [base[0], base[1] + arrowLength];
  const port: Position = [
    base[0] - widthLength,
    base[1] + arrowLength - heightLength,
  ];
  const starboard: Position = [
    base[0] + widthLength,
    base[1] + arrowLength - heightLength,
  ];

  let line = multiLineString(
    [
      [base, head],
      [port, head, starboard],
    ],
    { name: feature.properties.name }
  );
  line = transformRotate(line, feature.properties.course_over_ground, {
    pivot: base,
  });
  return { ...line, properties: feature.properties };
};

// pointsArrayToLineString returns a lineString from a FeatureCollection of Points
export const pointsToLineString = (
  waypoints: VesselsFeatures | WaypointsFeatures,
  properties?: { [name: string]: any }
) => {
  if (!waypoints.features || waypoints.features.length === 0) {
    return undefined;
  }
  const linestring = lineString(
    waypoints.features?.map((feature) => feature.geometry.coordinates),
    properties
  ) as GeoJSON.GeoJSON;

  return linestring;
};

// pointsFeatureCollectionToLineGraphics returns a ArcGIS Graphics[] from a FeatureCollection of Points
export const pointsFeatureCollectionToSingleGraphic =
  (f = pointsToLineString) =>
  (
    data: VesselsFeatures | WaypointsFeatures,
    properties?: { [name: string]: any }
  ) => {
    if (!data.features || data.features.length === 0) {
      return undefined;
    }
    return [Graphic.fromJSON(geojsonToArcGIS(f(data, properties)))];
  };

// getWaypointCircle returns a circle from a Point
export const getWaypointCircle = (feature: WaypointFeature) =>
  circle(feature, feature.properties.radius, {
    steps: 16,
    units: "meters",
    properties: feature.properties,
  });

export const featureIdentityTransform = (
  feature: VesselFeature | WaypointFeature | GeometryFeature
) => feature as GeoJSON.GeoJSON;

export const featuresToGraphicsWithTransform =
  (transform = featureIdentityTransform) =>
  (data: VesselsFeatures | WaypointsFeatures | GeometriesFeatures) =>
    data.features?.map((feature) =>
      Graphic.fromJSON(geojsonToArcGIS(transform(feature)))
    );

export const graphicsWithSymbolFromFeatures = (
  data: VesselsFeatures | WaypointsFeatures | GeometriesFeatures,
  featuresToGraphics = featuresToGraphicsWithTransform(featureIdentityTransform)
) => {
  if (!data.features) {
    return [];
  }
  const graphics = featuresToGraphics(data);
  graphics.forEach((graphic, i) => {
    const color = data.features[i].properties?.color || "red";

    let symbol: Symbol;
    switch (graphic.geometry.type) {
      case "polygon":
        symbol = new SimpleFillSymbol({
          color,
          outline: {
            color,
            width: 2,
          },
        });
        break;
      case "point":
        symbol = new SimpleMarkerSymbol({
          color,
          size: 8,
          outline: {
            color,
            width: 0,
          },
        });
        break;
      case "polyline":
        symbol = new SimpleLineSymbol({
          color,
          width: 2,
        });
        break;
      default:
        break;
    }
    graphic.set("symbol", symbol);
  });
  return graphics;
};

export const updateFeaturesLayer = (
  layer: FeatureLayer,
  data: VesselsFeatures | WaypointsFeatures | GeometriesFeatures,
  attributeString: string = "mmsi",
  featuresToGraphics = featuresToGraphicsWithTransform(featureIdentityTransform)
) => {
  if (layer.loaded) {
    const attributeToObjectIdMap = new Map<number | string, number>();
    layer
      .queryFeatures({
        outFields: ["*"],
        returnGeometry: true,
      })
      .then((queryResult) => {
        queryResult.features.forEach((f) => {
          attributeToObjectIdMap.set(
            f.attributes[attributeString],
            f.attributes.ObjectId
          );
        });
      })
      .then(() => {
        const arcgisGraphics = featuresToGraphics(data);
        const newAttributesList = arcgisGraphics?.map(
          (f) => f.attributes[attributeString]
        );

        const existingAttributesList = Array.from(
          attributeToObjectIdMap.keys()
        );
        const attributesToUpdate = existingAttributesList.filter((attribute) =>
          newAttributesList.includes(attribute)
        );
        arcgisGraphics?.forEach((graphic) => {
          graphic.setAttribute(
            "ObjectId",
            attributeToObjectIdMap.get(graphic.attributes[attributeString])
          );
        });

        const attributesToAdd = newAttributesList?.filter(
          (attribute) => !existingAttributesList.includes(attribute)
        );
        const attributesToDelete = existingAttributesList?.filter(
          (attribute) => !newAttributesList.includes(attribute)
        );
        const objectIdsToRemove = attributesToDelete?.map((attribute) => ({
          objectId: attributeToObjectIdMap.get(attribute),
        }));
        layer
          .applyEdits(
            {
              addFeatures: arcgisGraphics?.filter((g) =>
                attributesToAdd.includes(g.attributes[attributeString])
              ),
              updateFeatures: arcgisGraphics?.filter((g) =>
                attributesToUpdate.includes(g.attributes[attributeString])
              ),
              deleteFeatures: objectIdsToRemove,
            },
            {
              globalIdUsed: false,
            }
          )
          .catch((err) => {
            console.log(err);
          });
      });
  }
};

export const getLastFeature = (data: VesselsFeatures) =>
  data.features ? data.features[data.features.length - 1] : undefined;
