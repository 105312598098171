import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import {
  AISTrianlgeRenderer,
  AISPolygonRenderer,
  AISCoGArrowRenderer,
} from "./renderers";

import { VesselsFeatures } from "../interfaces";
import {
  featureIdentityTransform,
  featuresToGraphicsWithTransform,
  getVesselCourseOverGroundArrow,
  getVesselShape,
  updateFeaturesLayer,
} from "../utils";

export interface AISProps {
  aisData?: VesselsFeatures;
}

export const AISPolygonLayer = ({ aisData }: AISProps) => {
  const arcgisGraphics =
    featuresToGraphicsWithTransform(getVesselShape)(aisData);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "polygon",
    renderer: AISPolygonRenderer,
    title: "AIS Polygon Layer",
    minScale: 18000,
    outFields: ["*"],
    id: "ais-polygon-layer",
  });

  return layer;
};

export const AISTrianglesLayer = ({ aisData }: AISProps) => {
  const arcgisGraphics = featuresToGraphicsWithTransform(
    featureIdentityTransform
  )(aisData);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
      {
        name: "heading",
        alias: "Heading",
        type: "double",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "point",
    renderer: AISTrianlgeRenderer,
    title: "AIS Triangles Layer",
    outFields: ["*"],
    id: "ais-triangles-layer",
  });

  return layer;
};

export const AISCourseOverGroundArrowLayer = ({ aisData }: AISProps) => {
  const arcgisGraphics = featuresToGraphicsWithTransform(
    getVesselCourseOverGroundArrow
  )(aisData);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "polyline",
    renderer: AISCoGArrowRenderer,
    title: "AIS Course Over Ground Arrow Layer",
    minScale: 18000,
    outFields: ["*"],
    id: "ais-course-over-ground-arrow-layer",
  });

  return layer;
};

export const AISLayers = ({ aisData }: AISProps) => {
  if (!aisData) {
    return [];
  }
  const layers = [
    AISPolygonLayer({ aisData }),
    AISTrianglesLayer({ aisData }),
    AISCourseOverGroundArrowLayer({ aisData }),
  ];
  return layers;
};

export const updateAISLayers = (
  layers: FeatureLayer[],
  aisData: VesselsFeatures
) => {
  layers.forEach((layer) => {
    let featuresToGraphics = featuresToGraphicsWithTransform(
      featureIdentityTransform
    );
    switch (layer.id) {
      case "ais-polygon-layer":
        featuresToGraphics = featuresToGraphicsWithTransform(getVesselShape);
        break;
      case "ais-course-over-ground-arrow-layer":
        featuresToGraphics = featuresToGraphicsWithTransform(
          getVesselCourseOverGroundArrow
        );
        break;
      default:
        break;
    }
    if (layer.loaded) {
      updateFeaturesLayer(layer, aisData, "mmsi", featuresToGraphics);
    }
  });
};
