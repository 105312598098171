// import OpenStreetMapLayer from "@arcgis/core/layers/OpenStreetMapLayer";
import OpenStreetMapLayer from "@arcgis/core/layers/WebTileLayer";

export interface OpenSeaMapProps {
  url?: string;
  title?: string;
}

const defaultUrlTemplate_ =
  "http://t1.openseamap.org/seamark/{level}/{col}/{row}.png";
const defaultTitle_ = "OpenSeaMap";

export const OpenSeaMapLayer = ({
  url = defaultUrlTemplate_,
  title = defaultTitle_,
}: OpenSeaMapProps) =>
  new OpenStreetMapLayer({
    urlTemplate: url,
    subDomains: ["t1"],
    title,
  });
