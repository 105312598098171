import React from "react";
import "../css";

export type BSTypographyProps = {
  size?: "s" | "m" | "l";
  enhanced?: boolean;
  variant?:
    | "title"
    | "body"
    | "body-active"
    | "instrument-label"
    | "instrument-dynamic"
    | "instrument-static"
    | "instrument-input"
    | "instrument-unit"
    | "instrument-status"
    | "instrument-value";

  style?: React.CSSProperties;

  children: React.ReactNode;
};

export const BSTypography = (props: BSTypographyProps) => {
  const { size, enhanced, variant, children, style } = props;

  let classes = "text-style-";

  if (
    variant === "instrument-dynamic" ||
    variant === "instrument-static" ||
    variant === "instrument-input"
  ) {
    classes += `instrument-value-${size}${
      enhanced ? "-enhanced" : ""
    } ${variant}`;
  } else if (variant?.startsWith("instrument-")) {
    classes += `${variant}-${size} ${variant}`;
  } else {
    classes += `ui-${variant}`;
  }

  return (
    <span className={classes} style={style}>
      {children}
    </span>
  );
};
