import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import RotationVariable from "@arcgis/core/renderers/visualVariables/RotationVariable";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import { PictureMarkerSymbol, SimpleLineSymbol } from "@arcgis/core/symbols";

// AIS Triangle renderer
export const AISTrianlgeRenderer = new SimpleRenderer({
  symbol: new SimpleMarkerSymbol({
    path: "M150 0 L75 200 L225 200 Z",
    outline: {
      color: "green",
      width: 2,
    },
    angle: 0,
  }),
  visualVariables: [
    new RotationVariable({
      field: "heading",
      rotationType: "geographic",
    }),
  ],
});

// Vessel Track renderer
const VesselTrackRenderer = new SimpleRenderer({
  symbol: new SimpleMarkerSymbol({
    style: "circle",
    color: "grey",
    size: "2.5px",
    outline: {
      color: "grey",
      width: "0.5px",
    },
  }),
});
export const OwnshipTrackRenderer = VesselTrackRenderer;

// Line renderer
const LineRenderer = new SimpleRenderer({
  symbol: new SimpleLineSymbol({
    style: "dash",
    color: "yellow",
    width: "3px",
  }),
});
export const WaypointsLineRenderer = LineRenderer;

// Polygon renderer
const PolygonRenderer = new SimpleRenderer({
  symbol: new SimpleFillSymbol({
    color: [34, 139, 34, 0.3],
    outline: {
      color: "green",
      width: 2,
    },
  }),
});
export const AISPolygonRenderer = PolygonRenderer;
export const OwnshipPolygonRenderer = PolygonRenderer.clone()
  .set("symbol.color", [255, 0, 0, 0.3])
  .set("symbol.outline.color", "red");
export const WaypointsPolygonRenderer = PolygonRenderer.clone()
  .set("symbol.color", [255, 255, 0, 0.3])
  .set("symbol.outline.color", "yellow");

// Course Over Ground Arrow
const CourseOverGroundArrowRenderer = new SimpleRenderer({
  symbol: new SimpleLineSymbol({
    color: [34, 139, 34, 0.9],
    width: 1,
  }),
});
export const AISCoGArrowRenderer = CourseOverGroundArrowRenderer;
export const OwnshipCoGArrowRenderer =
  CourseOverGroundArrowRenderer.clone().set("symbol.color", [255, 0, 0, 0.9]);

// Ownship Marker Renderer
const PositionMarkerImage =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDEwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPgo8Y2lyY2xlIGN4PSI0OS41IiBjeT0iOTkuNSIgcj0iMTIiIHN0cm9rZT0iYmxhY2siLz4KPGNpcmNsZSBjeD0iNTAiIGN5PSIxMDAiIHI9IjI0LjUiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTS0xMDAgMTAwSDIwMCIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNTAgMTAwTDUwIC0yMDAiIHN0cm9rZT0iYmxhY2siLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMjAwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=";
export const OwnshipMarkerRenderer = new SimpleRenderer({
  symbol: new PictureMarkerSymbol({
    url: PositionMarkerImage,
    // TODO: documentation says max width and height are 200px!!
    width: "200px",
    height: "400px",
  }),
  visualVariables: [
    new RotationVariable({
      field: "heading",
      rotationType: "geographic",
    }),
  ],
});

// Point Marker Renderer
const PointRenderer = new SimpleRenderer({
  symbol: new SimpleMarkerSymbol({
    style: "circle",
    color: "red",
    size: "2.5px",
    outline: {
      color: "grey",
      width: "0.5px",
    },
  }),
});
export const GeometryPointRenderer = PointRenderer;
