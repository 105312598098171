import React from "react";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import { BSIcon } from "@blksail/blksail-components";
import SvgIcon from "@mui/icons-material/SettingsBrightness";

export interface IChangeColorButtonProps {
  onClick?: () => void;
}

export const ChangeColorButton = ({ onClick }: IChangeColorButtonProps) => (
  <BSIcon
    icon={(<SettingsBrightnessIcon />) as unknown as typeof SvgIcon}
    flavor="flat"
    emphasized={false}
    // onClick={handleClick}
    onClick={onClick}
    style={{
      paddingRight: "0.25rem",
      paddingLeft: "0.25rem",
    }}
  />
);
