import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

export interface ENCProps {
  url?: string;
  title?: string;
  colorScheme?: "bright" | "day" | "dusk" | "night";
}

const DEFAULT_NOAA_URL =
  "https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/MapServer";
const DEFAULT_TITLE = "NOAA ENC";
const DEFAULT_PARAMS = {
  ECDISParameters: {
    version: "10.9",
    DynamicParameters: {
      Parameter: [
        { name: "AreaSymbolizationType", value: 2 },
        { name: "AttDesc", value: 1 },
        { name: "ColorScheme", value: 0 },
        { name: "CompassRose", value: 1 },
        { name: "DateDependencyRange", value: "20220124-20220124" },
        { name: "DateDependencySymbols", value: 1 },
        { name: "DeepContour", value: 30 },
        { name: "DisplayAIOFeatures", value: "1,2,3,4,5,6,7" },
        { name: "DisplayBathymetricIENC", value: 1 },
        { name: "DisplayCategory", value: "1,2,4" },
        { name: "DisplayDepthUnits", value: 1 },
        { name: "DisplayFrames", value: 1 },
        { name: "DisplayFrameText", value: 1 },
        { name: "DisplayFrameTextPlacement", value: 1 },
        { name: "DisplayLightSectors", value: 2 },
        { name: "DisplayNOBJNM", value: 2 },
        { name: "DisplaySafeSoundings", value: 2 },
        { name: "HonorScamin", value: 2 },
        { name: "IntendedUsage", value: "0" },
        { name: "IsolatedDangers", value: 1 },
        { name: "IsolatedDangersOff", value: 1 },
        { name: "LabelContours", value: 1 },
        { name: "LabelSafetyContours", value: 1 },
        { name: "MovingCentroid", value: 1 },
        { name: "OptionalDeepSoundings", value: 1 },
        { name: "PointSymbolizationType", value: 2 },
        { name: "RemoveDuplicateText", value: 2 },
        { name: "SafetyContour", value: 30 },
        { name: "SafetyDepth", value: 30 },
        { name: "ShallowContour", value: 2 },
        { name: "TextHalo", value: 2 },
        { name: "TwoDepthShades", value: 1 },
      ],
      ParameterGroup: [
        {
          name: "AreaSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 1 },
            { name: "maxZoom", value: 1 },
          ],
        },
        {
          name: "DatasetDisplayRange",
          Parameter: [
            { name: "minZoom", value: 0.05 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
        {
          name: "LineSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 1 },
            { name: "maxZoom", value: 1 },
          ],
        },
        {
          name: "PointSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 1 },
            { name: "maxZoom", value: 1 },
          ],
        },
        {
          name: "TextGroups",
          Parameter: [
            { name: "11", value: 2 },
            { name: "21", value: 2 },
            { name: "23", value: 2 },
            { name: "24", value: 2 },
            { name: "25", value: 2 },
            { name: "26", value: 2 },
            { name: "27", value: 2 },
            { name: "28", value: 2 },
            { name: "29", value: 2 },
            { name: "30", value: 2 },
          ],
        },
        {
          name: "TextSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 1 },
            { name: "maxZoom", value: 1 },
          ],
        },
      ],
    },
  },
};

export const ENCLayer = ({
  url = DEFAULT_NOAA_URL,
  title = DEFAULT_TITLE,
  colorScheme = "day",
}: ENCProps) => {
  const params = DEFAULT_PARAMS;
  switch (colorScheme) {
    case "dusk":
      params.ECDISParameters.DynamicParameters.Parameter[2].value = 4;
      break;
    case "night":
      params.ECDISParameters.DynamicParameters.Parameter[2].value = 5;
      break;
    case "bright":
    case "day":
    default:
      params.ECDISParameters.DynamicParameters.Parameter[2].value = 0;
      break;
  }

  return new MapImageLayer({
    url,
    title,
    customParameters: {
      display_params: JSON.stringify(params),
    },
  });
};
