import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import {
  OwnshipPolygonRenderer,
  OwnshipTrackRenderer,
  OwnshipCoGArrowRenderer,
  OwnshipMarkerRenderer,
} from "./renderers";

import { VesselsFeatures } from "../interfaces";
import {
  featureIdentityTransform,
  featuresToGraphicsWithTransform,
  getLastFeature,
  getVesselCourseOverGroundArrow,
  getVesselShape,
  pointsFeatureCollectionToSingleGraphic,
  updateFeaturesLayer,
} from "../utils";

export interface OwnshipProps {
  ownshipTrack?: VesselsFeatures;
}

export const OwnshipPolygonLayer = ({ ownshipTrack }: OwnshipProps) => {
  const arcgisGraphics = pointsFeatureCollectionToSingleGraphic((data) =>
    getVesselShape(getLastFeature(data as VesselsFeatures))
  )(ownshipTrack);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "polygon",
    renderer: OwnshipPolygonRenderer,
    title: "Ownship Polygon Layer",
    minScale: 18000,
    outFields: ["*"],
    id: "ownship-polygon-layer",
  });

  return layer;
};

export const OwnshipTrackLayer = ({ ownshipTrack }: OwnshipProps) => {
  const arcgisGraphics = featuresToGraphicsWithTransform()(ownshipTrack);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
      {
        name: "heading",
        alias: "Heading",
        type: "double",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "point",
    renderer: OwnshipTrackRenderer,
    title: "Ownship Symbol Layer",
    outFields: ["*"],
    id: "ownship-track-layer",
  });

  return layer;
};

export const OwnshipCourseOverGroundArrowLayer = ({
  ownshipTrack,
}: OwnshipProps) => {
  const arcgisGraphics = pointsFeatureCollectionToSingleGraphic((data) =>
    getVesselCourseOverGroundArrow(getLastFeature(data as VesselsFeatures))
  )(ownshipTrack);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
    ],
    source: arcgisGraphics || [],
    geometryType: "polyline",
    renderer: OwnshipCoGArrowRenderer,
    title: "Ownship Course Over Ground Arrow Layer",
    minScale: 18000,
    outFields: ["*"],
    id: "ownship-course-over-ground-arrow-layer",
  });

  return layer;
};

export const OwnshipSymbolLayer = ({ ownshipTrack }: OwnshipProps) => {
  const arcgisGraphics =
    pointsFeatureCollectionToSingleGraphic(getLastFeature)(ownshipTrack);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: [
      {
        name: "mmsi",
        alias: "MMSI",
        type: "integer",
      },
      {
        name: "heading",
        alias: "Heading",
        type: "double",
      },
    ],
    maxScale: 10000,
    source: arcgisGraphics || [],
    geometryType: "point",
    renderer: OwnshipMarkerRenderer,
    title: "Ownship Course Over Ground Arrow Layer",
    outFields: ["*"],
    id: "ownship-symbol-layer",
  });

  return layer;
};

export const OwnshipLayers = ({ ownshipTrack }: OwnshipProps) => {
  if (!ownshipTrack) {
    return [];
  }
  const layers = [
    OwnshipPolygonLayer({ ownshipTrack }),
    // OwnshipTrackLayer({ ownshipTrack }),
    OwnshipCourseOverGroundArrowLayer({ ownshipTrack }),
    OwnshipSymbolLayer({ ownshipTrack }),
  ];
  return layers;
};

export const updateOwnshipLayers = (
  layers: FeatureLayer[],
  ownshipTrack: VesselsFeatures
) => {
  layers.forEach((layer) => {
    if (layer.loaded) {
      if (layer.id === "ownship-track-layer") {
        // ideally, we access the parent map of layer, destroy the layer, and then recreate it
        // but this is not possible in the current version of the API
        // so we have to destroy the layer and then recreate it
        // layer.destroy();
        // layer.set = OwnshipTrackLayer({ ownshipTrack });
      } else {
        let featuresToGraphics = featuresToGraphicsWithTransform(
          featureIdentityTransform
        );
        switch (layer.id) {
          case "ownship-polygon-layer":
            featuresToGraphics = pointsFeatureCollectionToSingleGraphic(
              (data) => getVesselShape(getLastFeature(data as VesselsFeatures))
            );
            break;
          case "ownship-course-over-ground-arrow-layer":
            featuresToGraphics = pointsFeatureCollectionToSingleGraphic(
              (data) =>
                getVesselCourseOverGroundArrow(
                  getLastFeature(data as VesselsFeatures)
                )
            );
            break;
          case "ownship-symbol-layer":
            featuresToGraphics =
              pointsFeatureCollectionToSingleGraphic(getLastFeature);
            break;
          default:
            break;
        }
        updateFeaturesLayer(layer, ownshipTrack, "mmsi", featuresToGraphics);
      }
    }
  });
};
