import React, { useEffect, useRef, useState } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import { ENCLayer } from "./layers/ENC";
import { OpenSeaMapLayer } from "./layers/OpenSeaMap";

import "./MaritimeChart.css";
import { AISLayers, updateAISLayers } from "./layers/AIS";
import { VesselsFeatures, WaypointsFeatures } from "./interfaces";
import { OwnshipLayers, updateOwnshipLayers } from "./layers/Ownship";
import { updateWaypointsLayers, waypointsLayers } from "./layers/Waypoints";
import { FeatureCollection, Point, Polygon, LineString } from "@turf/helpers";
import {
  geometriesGraphicsLayer,
  updateGraphicsLayer,
} from "./layers/Geometries";

export interface MaritimeChartProps {
  enable3d?: boolean;
  center?: [number, number];
  zoom?: number;
  showENC?: boolean;
  colorSchemeENC?: "bright" | "day" | "dusk" | "night";
  showOpenSeaMap?: boolean;
  aisData?: VesselsFeatures;
  showAIS?: boolean;
  ownshipTrack?: VesselsFeatures;
  showOwnship?: boolean;
  waypoints?: WaypointsFeatures;
  showWaypoints?: boolean;
  points?: FeatureCollection<Point>;
  polygons?: FeatureCollection<Polygon>;
  lineStrings?: FeatureCollection<LineString>;
}

const DEFAULT_CENTER = [-71.0, 42.0] as [number, number];
const DEFAULT_ZOOM = 5;

const ENC_Z_INDEX = 1;
const OSM_Z_INDEX = 0;
const AIS_Z_INDEX = 90;
const OWN_Z_INDEX = 100;
const WAY_Z_INDEX = 80;
const POINTS_Z_INDEX = 12;
const POLYGONS_Z_INDEX = 10;
const LINESTRINGS_Z_INDEX = 11;

export const MaritimeChart = ({
  // enable3d = false,
  center = DEFAULT_CENTER,
  zoom = DEFAULT_ZOOM,
  showENC = true,
  colorSchemeENC = "day",
  showOpenSeaMap = true,
  aisData = {} as VesselsFeatures,
  showAIS = true,
  ownshipTrack = {} as VesselsFeatures,
  showOwnship = true,
  waypoints = { features: [] } as WaypointsFeatures,
  showWaypoints = true,
  points = {} as FeatureCollection<Point>,
  polygons = { features: [] } as FeatureCollection<Polygon>,
  lineStrings = {} as FeatureCollection<LineString>,
}: MaritimeChartProps) => {
  // Base map and view
  const mapDiv = useRef<HTMLInputElement>(null);
  // const [map, setMap] = useState<Map | undefined>(undefined);
  const [view, setView] = useState<MapView | SceneView | undefined>(undefined);

  // ENC layer
  const [encLayer, setEncLayer] = useState<
    ReturnType<typeof ENCLayer> | undefined
  >(undefined);
  const [colorSchemeENCState, setColorSchemeENCState] =
    useState(colorSchemeENC);

  // OpenSeaMap layer
  const [openSeaMapLayer, setOpenSeaMapLayer] = useState<
    ReturnType<typeof OpenSeaMapLayer> | undefined
  >(undefined);

  // AIS layers
  const [aisLayers, setAisLayers] = useState<
    ReturnType<typeof AISLayers> | undefined
  >(undefined);

  // Ownship layers
  const [ownshipLayers, setOwnshipLayers] = useState<
    ReturnType<typeof OwnshipLayers> | undefined
  >(undefined);

  // Waypoints layers
  const [waypointLayers, setWaypointsLayers] = useState<
    ReturnType<typeof waypointsLayers> | undefined
  >(undefined);

  // Points layer
  const [pointsLayer, setPointsLayer] = useState<
    ReturnType<typeof geometriesGraphicsLayer> | undefined
  >(undefined);
  // Polygons layer
  const [polygonsLayer, setPolygonsLayer] = useState<
    ReturnType<typeof geometriesGraphicsLayer> | undefined
  >(undefined);
  // LineStrings layer
  const [lineStringsLayer, setLineStringsLayer] = useState<
    ReturnType<typeof geometriesGraphicsLayer> | undefined
  >(undefined);

  useEffect(() => {
    if (mapDiv.current) {
      setView((view) => {
        // console.log("Setting initial view");
        if (view) {
          // console.log("Found existing view. Destroying it.");
          view.map.set(null);
          view.destroy();
        }

        const newMap = new Map({
          basemap: "dark-gray-vector",
          ground: "world-elevation",
        });

        return new MapView({
          container: mapDiv.current,
          map: newMap,
          zoom,
          center,
        });
      });
    }
    return () => {
      if (view) {
        // console.log("Destroying Final view");
        setView((view) => {
          if (view) {
            view.destroy();
          }
          return null;
        });
      }
    };
  }, []);

  //--------------------------------------------------------------------------
  // add ENC with side effect at start
  useEffect(() => {
    const enc = ENCLayer({ colorScheme: colorSchemeENC });
    if (view) {
      view?.when(
        () => {
          view?.map.add(enc, ENC_Z_INDEX);
          enc.load().then(() => {
            enc.visible = showENC;
          });
          setEncLayer(enc);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => {
      if (enc) {
        view?.map.remove(enc);
      }
      setEncLayer((enc) => {
        if (enc) {
          enc.destroy();
        }
        return undefined;
      });
    };
  }, [view]);

  // add OpenSeaMap with side effect at start
  useEffect(() => {
    const openSeaMap = OpenSeaMapLayer({});
    if (view) {
      view?.when(
        () => {
          view?.map.add(openSeaMap, OSM_Z_INDEX);
          openSeaMap.load().then(() => {
            openSeaMap.visible = showOpenSeaMap;
          });
          setOpenSeaMapLayer(openSeaMap);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => {
      if (openSeaMap) {
        view?.map.remove(openSeaMap);
      }
      setOpenSeaMapLayer((openSeaMap) => {
        if (openSeaMap) {
          openSeaMap.destroy();
        }
        return undefined;
      });
    };
  }, [view]);

  // add AIS with side effect at start
  useEffect(() => {
    const ais = AISLayers({ aisData });
    if (view) {
      view?.when(
        () => {
          view?.map.addMany(ais, AIS_Z_INDEX);
          ais.forEach((l) =>
            l.load().then(() => {
              l.set("visible", showAIS);
            })
          );
          setAisLayers(ais);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => {
      if (ais) {
        view?.map.removeMany(ais);
      }
      setAisLayers((ais) => {
        if (ais) {
          ais.forEach((l) => l.destroy());
        }
        return undefined;
      });
    };
  }, [view]);

  // add Ownship with side effect at start
  useEffect(() => {
    const ownship = OwnshipLayers({ ownshipTrack });
    if (view) {
      view?.when(
        () => {
          view?.map.addMany(ownship, OWN_Z_INDEX);
          ownship.forEach((l) =>
            l.load().then(() => {
              l.set("visible", showOwnship);
            })
          );
          setOwnshipLayers(ownship);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => {
      if (ownship) {
        view?.map.removeMany(ownship);
      }
      setOwnshipLayers((ownship) => {
        if (ownship) {
          ownship.forEach((l) => l.destroy());
        }
        return undefined;
      });
    };
  }, [view]);

  // add Waypoints with side effect at start
  useEffect(() => {
    const waypointsLayer = waypointsLayers({ waypointsList: waypoints });
    if (view) {
      view?.when(
        () => {
          view?.map.addMany(waypointsLayer, WAY_Z_INDEX);
          waypointsLayer.forEach((l) =>
            l.load().then(() => {
              l.set("visible", showWaypoints);
            })
          );
          setWaypointsLayers(waypointsLayer);
        },
        (err) => {
          console.error(err);
        }
      );
    }
    return () => {
      if (waypointsLayer) {
        view?.map.removeMany(waypointsLayer);
      }
      setWaypointsLayers((waypointsLayer) => {
        if (waypointsLayer) {
          waypointsLayer.forEach((l) => l.destroy());
        }
        return undefined;
      });
    };
  }, [view]);

  // add points with side effect at start
  useEffect(() => {
    const pointsLayer = geometriesGraphicsLayer(
      points,
      "Points Layer",
      "points-layer"
    );
    if (view) {
      view?.when(
        () => {
          view?.map.add(pointsLayer, POINTS_Z_INDEX);
          setPointsLayer(pointsLayer);
        },
        (err) => {
          console.error(err);
        }
      );
    }
    return () => {
      if (pointsLayer) {
        view?.map.remove(pointsLayer);
      }
      setPointsLayer((pointsLayer) => {
        if (pointsLayer) {
          pointsLayer.destroy();
        }
        return undefined;
      });
    };
  }, [view]);

  // add lines with side effect at start
  useEffect(() => {
    const lineStringsLayer = geometriesGraphicsLayer(
      lineStrings,
      "LineStrings Layer",
      "line-strings-layer"
    );
    if (view) {
      view?.when(
        () => {
          view?.map.add(lineStringsLayer, LINESTRINGS_Z_INDEX);
          setLineStringsLayer(lineStringsLayer);
        },
        (err) => {
          console.error(err);
        }
      );
    }
    return () => {
      if (lineStringsLayer) {
        view?.map.remove(lineStringsLayer);
      }
      setLineStringsLayer((lineStringsLayer) => {
        if (lineStringsLayer) {
          lineStringsLayer.destroy();
        }
        return undefined;
      });
    };
  }, [view]);

  // add polygons with side effect at start
  useEffect(() => {
    const polygonsLayer = geometriesGraphicsLayer(
      polygons,
      "Polygons Layer",
      "polygons-layer"
    );
    if (view) {
      view?.when(
        () => {
          view?.map.add(polygonsLayer, POLYGONS_Z_INDEX);
          setPolygonsLayer(polygonsLayer);
        },
        (err) => {
          console.error(err);
        }
      );
    }
    return () => {
      if (polygonsLayer) {
        view?.map.remove(polygonsLayer);
      }
      setPolygonsLayer((polygonsLayer) => {
        if (polygonsLayer) {
          polygonsLayer.destroy();
        }
        return undefined;
      });
    };
  }, [view]);

  //--------------------------------------------------------------------------
  // view panel updates
  useEffect(() => {
    if (view) {
      view.goTo({
        zoom,
      });
    }
  }, [zoom]);
  useEffect(() => {
    if (view) {
      view.goTo({
        center,
      });
    }
  }, [center]);

  //--------------------------------------------------------------------------
  // visibility of layers
  useEffect(() => {
    if (view?.map) {
      if (encLayer) {
        encLayer.visible = showENC;
      }
    }
  }, [showENC]);
  useEffect(() => {
    if (view?.map) {
      if (openSeaMapLayer) {
        openSeaMapLayer.visible = showOpenSeaMap;
      }
    }
  }, [showOpenSeaMap]);
  useEffect(() => {
    if (view?.map) {
      if (aisLayers) {
        aisLayers.forEach((layer) => {
          layer.set("visible", showAIS);
        });
      }
    }
  }, [showAIS]);
  useEffect(() => {
    if (view?.map) {
      if (ownshipLayers) {
        ownshipLayers.forEach((layer) => {
          layer.set("visible", showOwnship);
        });
      }
    }
  }, [showOwnship]);
  useEffect(() => {
    if (view?.map) {
      if (waypointLayers) {
        waypointLayers.forEach((layer) => {
          layer.set("visible", showWaypoints);
        });
      }
    }
  }, [showWaypoints]);

  //--------------------------------------------------------------------------
  // update ENC color scheme
  useEffect(() => {
    if (view?.map && colorSchemeENC !== colorSchemeENCState) {
      encLayer.cancelLoad();
      view?.map.remove(encLayer);
      const newEncLayer = ENCLayer({ colorScheme: colorSchemeENC });
      setEncLayer(newEncLayer);
      newEncLayer.load().then(() => {
        view?.map.add(newEncLayer, 1);
      });
    }
    setColorSchemeENCState(colorSchemeENC);
  }, [colorSchemeENC]);

  //--------------------------------------------------------------------------
  // data updates
  useEffect(() => {
    if (aisLayers) {
      updateAISLayers(aisLayers, aisData);
    }
  }, [aisData]);

  useEffect(() => {
    if (view && view.map && ownshipLayers) {
      // check if all layers in ownshipLayers are loaded
      const allLoaded = ownshipLayers.every((layer) => layer.loaded);
      if (allLoaded) {
        updateOwnshipLayers(ownshipLayers, ownshipTrack);
      }
    }
  }, [ownshipTrack]);

  useEffect(() => {
    if (waypointLayers) {
      updateWaypointsLayers(waypointLayers, waypoints);
    }
  }, [waypoints]);

  useEffect(() => {
    if (pointsLayer) {
      // updatePointsLayer(pointsLayer, points);
      updateGraphicsLayer(pointsLayer, points);
    }
  }, [points]);

  useEffect(() => {
    if (lineStringsLayer) {
      updateGraphicsLayer(lineStringsLayer, lineStrings);
    }
  }, [lineStrings]);

  useEffect(() => {
    if (polygonsLayer) {
      updateGraphicsLayer(polygonsLayer, polygons);
    }
  }, [polygons]);

  return (
    <div className="maritime-chart">
      <div className="map-div" ref={mapDiv} />
    </div>
  );
};
