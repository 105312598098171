import * as React from "react";
import { BSInstrumentLabel, BSTypography } from "@blksail/blksail-components";

export interface CurrentAdvisedInstrumentProps {
  instrument: string;
  currentValue: string;
  advisedValue: string;
  unit: string;
}

export const CurrentAdvisedInstrument = (
  props: CurrentAdvisedInstrumentProps
) => {
  const { instrument, currentValue, advisedValue, unit } = props;
  return (
    <div
      // Left Column
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
      }}
    >
      <BSTypography variant="instrument-label" size="m">
        {instrument}
      </BSTypography>
      <BSInstrumentLabel
        label="Current"
        size="m"
        value={currentValue}
        unit={unit}
        enhanced={false}
        row={false}
        valueType="dynamic"
        baseLabel
      />
      <BSInstrumentLabel
        label="Advised"
        size="l"
        value={advisedValue}
        unit={unit}
        enhanced
        row={false}
        valueType="input"
        baseLabel
      />
    </div>
  );
};
