import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { GeometriesFeatures } from "../interfaces";
import { graphicsWithSymbolFromFeatures } from "../utils";

export interface GeometriesProps {
  geometriesFeatures?: GeometriesFeatures;
}

export const geometriesGraphicsLayer = (
  geometriesFeatures: GeometriesFeatures,
  title: string,
  id: string
) => {
  const arcgisGraphics = graphicsWithSymbolFromFeatures(geometriesFeatures);

  const layer = new GraphicsLayer({
    graphics: arcgisGraphics,
    title,
    id,
  });

  return layer;
};

export const updateGraphicsLayer = (
  layer: GraphicsLayer,
  features: GeometriesFeatures
) => {
  layer.removeAll();
  const arcgisGraphics = graphicsWithSymbolFromFeatures(features);
  layer.addMany(arcgisGraphics);
};
