import React from "react";
import { BSIcon } from "./BSIcon";
import { Card, CardHeader, CardContent } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { SvgIcon } from "@mui/material";

export interface BSCardProps {
  title: string;
  headerIcon?: typeof SvgIcon;
  headerActionIcon?: typeof SvgIcon;
  headerActionOnClick?: () => void;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const cardStyle = {
  backgroundColor: "var(--color-container-background-color)",
  borderRadius: "0.125rem",
  display: "inline-flex",
  flexDirection: "column",
  flexGrow: 1,
} as React.CSSProperties;

const cardHeaderStyle = {
  padding: 0,
  paddingLeft: 2,
  height: "2.5rem",
  position: "relative",
  borderBottom: "1px solid var(--color-border-divider-color)",
  "&>.MuiCardHeader-avatar": {
    color: "var(--color-element-neutral-color)",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 1,
  },
  "&>.MuiCardHeader-content": {
    color: "var(--color-element-active-color)",
    margin: "auto 0",
    fontSize: "0.75rem",
    fontFamily: "Open Sans",
    fontWeight: "bolder",
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "none",
    letterSpacing: "1.125px",
    textTransform: "uppercase",
  },
  "&>.MuiCardHeader-action": {
    color: "var(--color-element-active-color)",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 1,
    borderLeft: "1px solid var(--color-border-divider-color)",
    "& *": {
      margin: "auto",
    },
  },
} as React.CSSProperties;

const cardContentStyle = {
  padding: "0.5rem 1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
} as React.CSSProperties;

export const BSCard = ({
  title,
  children,
  // headerActionIcon,
  // headerActionOnClick,
  headerIcon,
  sx,
}: BSCardProps) => (
  <Card
    sx={{
      ...(sx as React.CSSProperties),
      ...cardStyle,
    }}
  >
    <CardHeader
      sx={cardHeaderStyle}
      title={title}
      disableTypography
      avatar={
        <BSIcon
          flavor="flat"
          // icon={
          //   React.createElement(
          //     headerIcon!
          //   ) as unknown as React.ReactComponentElement<typeof SvgIcon>
          // }
          icon={headerIcon}
        />
      }
      // action={
      //   headerActionIcon && (
      //     <BSIcon
      //       icon={headerActionIcon}
      //       type="normal"
      //       onClick={headerActionOnClick}
      //     />
      //   )
      // }
    />
    <CardContent sx={cardContentStyle}>{children}</CardContent>
  </Card>
);
