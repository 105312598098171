import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { WaypointsLineRenderer, WaypointsPolygonRenderer } from "./renderers";

import { WaypointsFeatures } from "../interfaces";
import {
  getWaypointCircle,
  updateFeaturesLayer,
  featureIdentityTransform,
  featuresToGraphicsWithTransform,
  pointsToLineString,
  pointsFeatureCollectionToSingleGraphic,
} from "../utils";

const FIELDS: __esri.FieldProperties[] = [
  {
    name: "id",
    alias: "ID",
    type: "string",
  },
  {
    name: "radius",
    alias: "Radius",
    type: "double",
  },
];

export interface WaypointsProps {
  waypointsList?: WaypointsFeatures;
}

export const waypointsLineLayer = ({ waypointsList }: WaypointsProps) => {
  const arcgisGraphics = pointsFeatureCollectionToSingleGraphic(
    pointsToLineString
  )(waypointsList, { id: "waypoints-line" });

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: FIELDS,
    source: arcgisGraphics || [],
    geometryType: "polyline",
    renderer: WaypointsLineRenderer,
    title: "Waypoints Line Layer",
    outFields: ["*"],
    id: "waypoints-line-layer",
  });

  return layer;
};

export const waypointsSymbolsLayer = ({ waypointsList }: WaypointsProps) => {
  const arcgisGraphics =
    featuresToGraphicsWithTransform(getWaypointCircle)(waypointsList);

  const layer = new FeatureLayer({
    objectIdField: "ObjectId",
    fields: FIELDS,
    source: arcgisGraphics || [],
    geometryType: "polygon",
    renderer: WaypointsPolygonRenderer,
    title: "Waypoints Symbols Layer",
    outFields: ["*"],
    id: "waypoints-symbols-layer",
  });

  return layer;
};

export const waypointsLayers = ({ waypointsList }: WaypointsProps) => {
  if (!waypointsList) {
    return [];
  }
  const layers = [
    waypointsSymbolsLayer({ waypointsList }),
    waypointsLineLayer({ waypointsList }),
  ];
  return layers;
};

export const updateWaypointsLayers = (
  layers: FeatureLayer[],
  waypointsList: WaypointsFeatures
) => {
  layers.forEach((layer) => {
    if (layer.loaded) {
      let featuresToGraphics = featuresToGraphicsWithTransform(
        featureIdentityTransform
      );
      switch (layer.id) {
        case "waypoints-symbols-layer":
          featuresToGraphics =
            featuresToGraphicsWithTransform(getWaypointCircle);
          break;
        case "waypoints-line-layer":
          featuresToGraphics =
            pointsFeatureCollectionToSingleGraphic(pointsToLineString);
          break;
        default:
          break;
      }
      updateFeaturesLayer(layer, waypointsList, "id", featuresToGraphics);
    }
  });
};
